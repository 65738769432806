import React, { useEffect, useState } from 'react';
import { COUNTRIES, STATES, PROVINCES, ModelListItem } from 'const';
import { useStore } from 'store/store';
import { Post } from 'api';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Button from 'components/Button/Button';
import ComparisonForm from 'components/ComparisonForm/ComparisonForm';
import Footer from 'components/Footer/Footer';
import Footnote from 'components/Footnote/Footnote';
import FreezerGraphic from 'components/FreezerGraphic/FreezerGraphic';
import OrLine from 'components/OrLine/OrLine';
import OnOff from 'components/Forms/OnOff/OnOff';
import Panel from 'components/Panel/Panel';
import Plus from 'icons/Plus';
import Radio from 'components/Forms/Radio/Radio';
import SelectBox from 'components/Forms/SelectBox/SelectBox';
import TextBox from 'components/Forms/TextBox/TextBox';
import './SingleComparison.scss';

/**
 * Full panel for the 1:1 comparison form.
 */
function SingleComparison() {
  const [state, dispatch] = useStore();
  const [errorMessage, setErrorMessage] = useState('');

  // Set the calculation type here for better breadcrumbs later.
  useEffect(() => {
    if (state.activePanel === 'single-comparison') {
      dispatch('SET_CALCULATION_TYPE', 'single');
      setErrorMessage('');
    }
  }, [state.activePanel, dispatch]);

  /**
   * Returns the list of relevant states/provinces/territories based on user selection.
   */
  function getTerritories(): string[] {
    if (state.currentProject.country === 'United States') {
      return STATES;
    } else if (state.currentProject.country === 'Canada') {
      return PROVINCES;
    } else {
      return [];
    }
  }

  /**
   * Returns a string containing the relevant territory placeholder based on user selection.
   */
  function getTerritoryPlaceholder(): string {
    if (state.currentProject.country === 'United States') {
      return 'State';
    } else if (state.currentProject.country === 'Canada') {
      return 'Province';
    } else {
      return '';
    }
  }

  /**
   * Validate form fields for completion and formatting.
   */
  function handleFormValidation() {
    let message = '';

    if (state.currentProject.freezer === '') {
      message = 'Select a Stirling ULT freezer.';
    } else if (state.currentProject.competitor === '') {
      message = 'Select a ULT freezer to compare.';
    } else if (
      state.currentProject.competitorCost !== '' &&
      isNaN(state.currentProject.competitorCost)
    ) {
      message = 'Competitor price must be a number.';
    } else if (state.currentProject.country === '') {
      message = 'Select a country.';
    } else if (
      state.currentProject.country === 'United States' &&
      state.currentProject.state === ''
    ) {
      message = 'Select a State.';
    } else if (
      state.currentProject.country === 'Canada' &&
      state.currentProject.state === ''
    ) {
      message = 'Select a Province.';
    } else if (state.currentProject.currency === '') {
      message = 'Select a currency.';
    } else if (
      state.currentProject.energyCostOverride === true &&
      state.currentProject.energyCostCustom === ''
    ) {
      message = 'Enter the energy cost.';
    } else if (
      state.currentProject.energyCostOverride === true &&
      isNaN(state.currentProject.energyCostCustom)
    ) {
      message = 'Energy cost must be a number.';
    } else {
      // Check storage requirements.
      if (
        state.currentProject.storageRequirements === '' &&
        state.currentProject.freezerCountNeeded === ''
      ) {
        message =
          'Add storage requirements or the number of ULT freezers to replace.';
      } else if (isNaN(state.currentProject.storageRequirements)) {
        message = 'Storage requirements must be a number.';
      } else if (isNaN(state.currentProject.freezerCountNeeded)) {
        message = 'The number of ULT freezers must be a number.';
      }
    }

    if (message === '') {
      handleCalculation();
    } else {
      setErrorMessage(message);
    }
  }

  /**
   * Fires off the calculation request, then takes the user to the 1:1 results panel.
   */
  function handleCalculation() {
    dispatch('ACTIVATE_LOADING_SCREEN');

    Post('calculate/single', {
      freezer_id: state.freezerModels.find(
        (freezer: ModelListItem) =>
          freezer.model === state.currentProject.freezer
      ).id,
      competitor_id: state.competitorModels.find(
        (competitor: ModelListItem) =>
          competitor.model === state.currentProject.competitor
      ).id,
      competitor_cost: state.currentProject.competitorCost
        ? state.currentProject.competitorCost
        : null,
      energy_cost: state.currentProject.energyCostOverride
        ? state.currentProject.energyCostCustom
        : null,
      storage_requirements: state.currentProject.storageRequirements
        ? state.currentProject.storageRequirements
        : null,
      // This is a little weird, but it just pulls out the last word from the
      // storageUnits value and sends that over in the request.
      // The API is expecting "liters", "feet", or "boxes".
      storage_requirements_unit: state.currentProject.storageUnits
        .toLowerCase()
        .split(' ')
        .pop(),
      freezer_number: state.currentProject.freezerCountNeeded
        ? state.currentProject.freezerCountNeeded
        : null,
      currency: state.currentProject.currency.toLowerCase(),
      country: state.currentProject.country,
      state: state.currentProject.state ? state.currentProject.state : null,
    }).then((response) => {
      dispatch('SET_RESULT', response.data);
      dispatch('SET_ACTIVE_PANEL', 'single-results');
      dispatch('DEACTIVATE_LOADING_SCREEN');
    });
  }

  /**
   * User changed value of the competitor select box.
   */
  function handleCompetitorChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'competitor', value: val });
  }

  /**
   * User changed value of the competitor cost text box.
   */
  function handleCompetitorCostChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'competitorCost', value: val });
  }

  /**
   * User changed value of country select box.
   */
  function handleCountryChange(val: string) {
    // Show the state select if United States is selected.
    if (
      state.currentProject.country !== 'United States' &&
      state.currentProject.country !== 'Canada'
    ) {
      dispatch('UPDATE_PROJECT_VALUE', { field: 'state', value: '' });
    }

    dispatch('UPDATE_PROJECT_VALUE', { field: 'country', value: val });
  }

  /**
   * User changed value of currency.
   */
  function handleCurrencyChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'currency', value: val });
  }

  /**
   * User changed value of the custom energy cost text box.
   */
  function handleEnergyCostCustom(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'energyCostCustom', value: val });
  }

  /**
   * Enable/disable the energy cost override text box.
   */
  function handleEnergyCostOverride(val: boolean) {
    dispatch('UPDATE_PROJECT_VALUE', {
      field: 'energyCostOverride',
      value: val,
    });
  }

  /**
   * User changed value of the freezer select box.
   */
  function handleFreezerChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'freezer', value: val });
  }

  /**
   * User changed value of the number of competitor freezers to replace.
   */
  function handleFreezerCountNeeded(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', {
      field: 'freezerCountNeeded',
      value: val,
    });
  }

  /**
   * User changed value of the measuerd units.
   */
  function handleMedsureUnitsChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'measureUnits', value: val });
  }

  /**
   * User changed value of the state select box.
   */
  function handleStateChange(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'state', value: val });
  }

  /**
   * User changed value of the storage requremets text box.
   */
  function handleStorageRequirements(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', {
      field: 'storageRequirements',
      value: val,
    });
  }

  /**
   * User changed value of the storage requirements units select box.
   */
  function handleStorageUnits(val: string) {
    dispatch('UPDATE_PROJECT_VALUE', { field: 'storageUnits', value: val });
  }

  return (
    <Panel id="single-comparison">
      <div className="single-comparison">
        <Breadcrumbs
          crumbs={[
            {
              onClick: () => {
                dispatch('SET_ACTIVE_PANEL', 'home');
              },
              title: 'Home',
            },
            {
              title: '1:1 Comparison Input',
            },
          ]}
        />
        <FreezerGraphic
          title="Personalize Your Plan"
          copy="This page is designed to personalize your lab plan. Whether looking to buy a new freezer and compare directly between models or replacing aging freezers, the calculation is specific to your geographic location, energy rates, and volumetric needs. Please complete the form to the right and press the ‘Calculate your Savings button."
          background={true}
        />
        <div className="right">
          <Button
            type="small"
            onClick={() => {
              dispatch('TOGGLE_SAVE_MODAL');
            }}
          >
            Save Project
            <Plus iconTitle="save-project-plus" />
          </Button>
          <ComparisonForm>
            <div className="full">
              <SelectBox
                label="Stirling ULT"
                name="singleFreezer"
                placeholder="Select model ..."
                options={state.freezerModels.map(
                  (freezer: ModelListItem) => freezer.model
                )}
                onChange={handleFreezerChange}
                value={state.currentProject.freezer}
              />
            </div>
            <div className="fifty-fifty">
              <SelectBox
                label="ULT you want to compare"
                name="singleCompetitor"
                placeholder="Select model ..."
                options={state.competitorModels.map(
                  (competitor: ModelListItem) => competitor.model
                )}
                onChange={handleCompetitorChange}
                value={state.currentProject.competitor}
              />
              <TextBox
                name="competitorCost"
                placeholder="Enter price (optional)"
                onChange={handleCompetitorCostChange}
                value={state.currentProject.competitorCost}
              />
            </div>
            <Footnote note="Freezer purchase price must be entered to compare equipment costs and calculate ROI." />
            <div className="section">
              <div className="fifty-fifty">
                <SelectBox
                  label="Determine your energy costs by location"
                  name="singleCountry"
                  placeholder="Country"
                  options={COUNTRIES}
                  onChange={handleCountryChange}
                  value={state.currentProject.country}
                />
                <SelectBox
                  className={`animate-visibility ${
                    state.currentProject.country === 'United States' ||
                    state.currentProject.country === 'Canada'
                      ? 'is-visible'
                      : ''
                  }`}
                  name="singleState"
                  placeholder={getTerritoryPlaceholder()}
                  options={getTerritories()}
                  onChange={handleStateChange}
                  value={state.currentProject.state}
                />
              </div>
              <div className="full">
                <SelectBox
                  name="singleCurrency"
                  onChange={handleCurrencyChange}
                  options={['USD', 'EUR']}
                  placeholder="Currency"
                  value={state.currentProject.currency}
                />
              </div>
            </div>
            <div className="section">
              <div className="fifty-fifty">
                <Radio
                  label="Select your unit of measure"
                  name="singleMeasureUnits"
                  options={['Metric', 'Imperial']}
                  onChange={handleMedsureUnitsChange}
                  value={state.currentProject.measureUnits}
                />
                <OnOff
                  label="Energy costs"
                  description="Override cost per kWh"
                  name="singleEnergyCostOverride"
                  onChange={handleEnergyCostOverride}
                  value={state.currentProject.energyCostOverride}
                />
              </div>
              <div
                className={`fifty-fifty animate-height ${
                  state.currentProject.energyCostOverride ? 'is-visible' : ''
                }`}
              >
                <div></div>
                <TextBox
                  name="singleCostOverride"
                  placeholder="Enter your cost per kWh"
                  onChange={handleEnergyCostCustom}
                  value={state.currentProject.energyCostCustom}
                />
              </div>
            </div>
            <div className="left-two-thirds">
              <TextBox
                label="Storage requirements"
                name="singleStorage"
                placeholder="Enter storage requirements"
                onChange={handleStorageRequirements}
                value={state.currentProject.storageRequirements}
              />
              <SelectBox
                name="singleStorageUnits"
                options={['Liters', 'Cubic Feet', '2" Boxes']}
                onChange={handleStorageUnits}
                value={state.currentProject.storageUnits}
              />
            </div>
            <OrLine />
            <div className="full">
              <TextBox
                label="Number of ULT freezers to replace"
                name="singleNumberFreezers"
                placeholder="Enter number of units"
                onChange={handleFreezerCountNeeded}
                value={state.currentProject.freezerCountNeeded}
              />
            </div>
            <div className={`error-message ${errorMessage ? 'is-active' : ''}`}>
              {errorMessage}
            </div>
            <Button type="basic" onClick={handleFormValidation}>
              Calculate your Savings
            </Button>
          </ComparisonForm>
        </div>

        <Footer />
      </div>
    </Panel>
  );
}

export default SingleComparison;
